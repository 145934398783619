import { Round2String } from "@/utils/Formatting";
import type { DocketRecordModel } from "@/utils/PocketBaseAdapter";
import { pb } from "@/utils/PocketBaseAdapter";
import { moment } from "@/utils/useTimeZone";

export const usePaymentStore = defineStore("paymentStore", () => {
  // Data
  const couponList = ref<any[]>([]);
  const docketId = ref<string>("");
  const docketLineId = ref<string>("");
  const docketPaymentId = ref<string>("");
  const lowBalanceError = ref<boolean>();
  const isPendingBooking = ref<boolean>(false);

  // Methods
  /**
   * Retrieves a list of coupons based on the provided user ID.
   * If no user ID is provided, it retrieves all available coupons.
   *
   * @param {string} userId - The ID of the user for whom to retrieve coupons.
   * @returns {Promise<any[]>} - A promise that resolves to an array of coupon objects.
   */
  const getCouponList = async (userId: any = null) => {
    try {
      const records = await pb.collection("coupons").getFullList({
        sort: "expiryTime",
        filter: userId
          ? `user = "${userId}" && qtyRemaining > 0`
          : "qtyRemaining > 0",
      });
      if (records) {
        couponList.value = records?.filter((coupon: any) => !coupon.expired);
      }
    } catch (e) {}
  };

  /**
   * Initiates a docket for a booking and creates or updates the corresponding docket line.
   *
   * @param bookingDetails - Details of the booking.
   * @param bookingId - The ID of the booking.
   *
   * @returns {Promise<void>} - A promise that resolves when the docket and docket line are created or updated.
   */
  const initiateDocketForBooking = async (
    bookingDetails: any,
    bookingId: string
  ) => {
    try {
      let currentDocketId = docketId.value;
      const totalAmount = bookingDetails?.amount || 0; // total amount before discount
      const discountRate = bookingDetails?.discountBookings || 0; // percentage discount

      // Calculate the total discount amount and due amount after payment
      const discountAmount = (totalAmount * discountRate) / 100;
      const due = totalAmount - discountAmount - (bookingDetails?.paid || 0);

      if (bookingDetails?.due < 0) {
        try {
          // Prepare docket data
          const docketData = {
            location: bookingDetails?.location,
            discountProduct: bookingDetails?.discountProduct,
            discountBookings: bookingDetails?.discountBookings,
            discountEvents: bookingDetails?.discountEvents,
            due: bookingDetails?.due, // Negative value indicating a refund
            total: bookingDetails?.amount,
            printList: false,
            user: bookingDetails?.owner,
            source: "Booking",
            discountSources: bookingDetails?.discountSources,
          };

          // Create a new docket
          const docketResponse = await pb.collection("dockets").create({
            ...docketData,
            createdBy: bookingDetails?.loginUser,
            status: "Paid",
          });

          if (docketResponse?.id) {
            const newDocketId = docketResponse.id;

            // Prepare docket line data
            const docketLineData = {
              docket: newDocketId, // Use the newly created docket ID
              transactionType: "Booking Payment",
              qty: bookingDetails?.qty || 1,
              itemPrice: bookingDetails?.itemPrice || totalAmount,
              discount: discountRate,
              lineTotal: Round2String(bookingDetails?.due, 2),
              userAccountCredit: bookingDetails?.owner,
              status: "Available",
              createdBy: bookingDetails?.loginUser,
              booking: bookingId,
            };

            // Create a new docket line
            const docketLineResponse = await pb
              .collection("docketLines")
              .create(docketLineData);

            if (docketLineResponse?.id) {
              docketLineId.value = docketLineResponse.id;

              // Update the booking with the new docket line ID
              await pb.collection("bookings").update(bookingId, {
                docketLine: docketLineResponse.id,
              });
            }
          }

          return;
        } catch (error) {
          console.error("Error initiating docket for booking:", error);
        }
      }

      // Create or update the docket
      let docketData: any = {
        location: bookingDetails?.location,
        discountProduct: bookingDetails?.discountProduct,
        discountBookings: bookingDetails?.discountBookings,
        discountEvents: bookingDetails?.discountEvents,
        due,
        total: bookingDetails?.amount,
        printList: false,
        user: bookingDetails?.owner,
        source: "Booking",
      };

      const discountSources = bookingDetails?.discountSources;

      if (!currentDocketId) {
        const docketResponse = await pb.collection("dockets").create({
          ...docketData,
          discountSources,
          createdBy: bookingDetails?.loginUser,
          status: "Open",
        });

        if (docketResponse) {
          currentDocketId = docketResponse.id;
          docketId.value = currentDocketId;
        }
      } else {
        if (due <= 0) bookingDetails.status = "Paid";

        await pb.collection("dockets").update(currentDocketId, {
          ...docketData,
          discountSources,
          updatedBy: bookingDetails?.loginUser,
        });
      }

      // Create or update the docket line
      if (currentDocketId) {
        const docketLineData = {
          docket: currentDocketId,
          transactionType: "Booking Payment",
          qty: bookingDetails?.qty || 1,
          itemPrice: bookingDetails?.itemPrice || totalAmount,
          discount: discountRate, // Apply booking discount rate to the docket line
          lineTotal: Round2String(totalAmount - discountAmount, 2), // Apply the calculated due after discount
          status: "Available",
          createdBy: bookingDetails?.loginUser,
          booking: bookingId,
        };

        if (docketLineId.value) {
          // Update the existing docket line
          await pb
            .collection("docketLines")
            .update(docketLineId.value, docketLineData);
        } else {
          // Create a new docket line
          const docketLineResponse = await pb
            .collection("docketLines")
            .create(docketLineData);
          if (docketLineResponse) {
            docketLineId.value = docketLineResponse.id;
            await pb.collection("bookings").update(bookingId, {
              docketLine: docketLineResponse.id,
            });
          }
        }
      }
    } catch (e) {
      console.error("Error initiating docket for booking:", e);
    }
  };

  /**
   * This function handles the payment process for gift cards or coupons.
   *
   * @param params - An object containing the payment details and other parameters.
   * @param params.status - The status of the payment.
   * @param params.paymentType - The type of payment (either "Coupon" or "Gift Card").
   * @param params.amount - The amount of the payment.
   * @param params.identifier - The identifier of the coupon or gift card.
   * @param params.qty - The quantity of the coupon.
   * @param params.toPay - The total amount to pay.
   * @param params.type - The type of payment (e.g., "Booking").
   * @param params.bookingId - The ID of the booking.
   * @param params.totalPaid - The total amount paid for the booking.
   * @param params.userId - The ID of the user making the payment.
   * @param params.payMethods - The payment methods used for the booking.
   *
   * @returns A promise that resolves to the updated coupon or gift card record.
   */
  const payWithGiftCardOrCoupon = async (params: {
    status?: string;
    paymentType: "Coupon" | "Gift Card";
    amount: number;
    identifier: string;
    qty: number;
    toPay: number;
    type?: string;
    bookingId?: string;
    totalPaid?: number;
    userId: string;
    payMethods?: string[];
  }) => {
    try {
      const createPaymentRecord = async (data: any) => {
        const record = await pb.collection("docketPayments").create(data);
        docketPaymentId.value = record.id;
        const balanceRemaining = params?.toPay - params?.amount;
        const status = balanceRemaining <= 0 ? "Paid" : "Partial";
        const due =
          balanceRemaining <= 0 ? 0 : Number(balanceRemaining).toFixed(2);

        const response = await pb
          .collection("dockets")
          .update<DocketRecordModel>(docketId.value, {
            status,
            due,
            user: data?.owner,
            source: "Booking",
            updatedBy: params?.userId,
          });

        if (params?.type === "Booking") {
          // update due of booking record
          await pb.collection("bookings").update(params?.bookingId, {
            due: due,
            paid: params.totalPaid + Number(params.amount),
            state: due === 0 ? "Confirmed" : "",
            payMethod: Array.from(
              new Set([...(params?.payMethods || []), params.paymentType])
            ),
          });
        }

        return response;
      };

      const data = {
        docket: docketId.value,
        paymentType: params.paymentType,
        amount: Number(params?.amount).toFixed(2),
        status: params.status,
        createdBy: params?.userId,
        ...(params.paymentType === "Coupon" && {
          coupon: params.identifier,
          couponQty: params.qty,
        }),
        ...(params.paymentType === "Gift Card" && {
          giftCard: params.identifier,
        }),
      };

      // Create a single payment record with couponQty instead of looping
      const response = await createPaymentRecord(data);

      // TODO: REMOVE CODE AFTER SERVER UPDATED WITH REAL VALUE OF COUPONS/GIFT
      // Update the remaining balance or quantity for the coupon or gift card
      // const collectionName =
      //   params.paymentType === "Coupon" ? "coupons" : "giftCards";
      // const response = await pb
      //   .collection(collectionName)
      //   .getOne(params.identifier);

      // if (params.paymentType === "Coupon") {
      //   response.qtyRemaining -= params.qty;
      // } else {
      //   response.balanceRemaining = (
      //     Number(response.balanceRemaining) - Number(params.amount)
      //   ).toFixed(2);
      // }
      // await pb.collection(collectionName).update(params.identifier, response);

      return response;
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * This function handles the payment process for applying multiple coupons or gift cards.
   *
   * @param params - An object containing the necessary parameters for the payment process.
   * @param params.status - The status of the payment.
   * @param params.paymentType - The type of payment (either "Coupon" or "Gift Card").
   * @param params.amount - The total amount to be paid.
   * @param params.identifier - An array of objects representing the coupons or gift cards.
   * @param params.qty - The quantity of coupons or gift cards.
   * @param params.toPay - The amount to be paid.
   * @param params.type - The type of payment (either "Booking" or "Membership").
   * @param params.bookingId - The ID of the booking (if the payment type is "Booking").
   * @param params.totalPaid - The total amount paid for the booking.
   * @param params.userId - The ID of the user making the payment.
   * @param params.payMethods - An array of payment methods used for the booking.
   *
   * @returns A Promise that resolves to `true` if the payment process is successful, otherwise it rejects with an error.
   */
  const payWithAutoApplyCoupons = async (params: {
    status?: string;
    paymentType: "Coupon" | "Gift Card";
    amount: number;
    identifier: any[];
    qty: number;
    toPay: number;
    type?: string;
    bookingId?: string;
    totalPaid?: number;
    userId: string;
    payMethods?: string[];
  }) => {
    try {
      const createPaymentRecord = async (data: any) => {
        const record = await pb.collection("docketPayments").create(data);
        docketPaymentId.value = record.id;
        const balanceRemaining = params?.toPay - data?.amount;
        const status = balanceRemaining <= 0 ? "Paid" : "Partial";
        const due =
          balanceRemaining <= 0 ? 0 : Number(balanceRemaining).toFixed(2);

        await pb
          .collection("dockets")
          .update<DocketRecordModel>(docketId.value, {
            status,
            due,
            user: data?.owner,
            source: "Booking",
            updatedBy: params?.userId,
          });

        if (params?.type === "Booking") {
          // Update due of booking record
          await pb.collection("bookings").update(params?.bookingId, {
            due: due,
            paid: params.totalPaid || 0 + data?.amount,
            state: due === 0 ? "Confirmed" : "",
            payMethod: Array.from(
              new Set([...(params?.payMethods || []), params.paymentType])
            ),
          });
        }

        if (params?.type === "Membership") {
          // Handle membership-specific logic here
        }
      };

      for (const coupon of params.identifier) {
        const data = {
          docket: docketId.value,
          paymentType: params.paymentType,
          amount: coupon.amount,
          status: params.status,
          createdBy: params?.userId,
          coupon: coupon.coupon,
          couponQty: coupon.qty,
        };

        // Create a payment record for each coupon
        await createPaymentRecord(data);

        // TODO: REMOVE CODE AFTER SERVER UPDATED WITH REAL VALUE OF COUPONS/GIFT
        // Update the remaining balance or quantity for the coupon
        // const response = await pb.collection("coupons").getOne(coupon.coupon);

        // response.qtyRemaining -= coupon.qty;

        // await pb.collection("coupons").update(coupon.coupon, response);
      }

      return true;
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Handles payment for a booking using user credit or waiving the fee.
   *
   * @param bookingDetails - Details of the booking.
   * @param bookingId - ID of the booking.
   * @param paymentType - Type of payment (either 'UserCredit' or 'WaiveFee').
   *
   * @throws Throws an error if the user credit is insufficient for the booking.
   *
   * @returns Nothing.
   */
  const handleUserCreditAndWaiveFeePayment = async (
    bookingDetails: any,
    bookingId: string,
    paymentType: string,
    notes?: string
  ) => {
    // Create a booking record with the specified payment type
    const docketPaymentData = {
      docket: docketId.value,
      paymentType: paymentType === "UserCredit" ? "User Account" : "Fee Waived",
      status: "Approved",
      amount: bookingDetails?.due,
      ...(paymentType === "UserCredit" && {
        user: bookingDetails?.owner,
      }),
      createdBy: bookingDetails?.loginUser,
      ...(paymentType === "WaiveFee" && {
        notes,
      }),
    };

    if (paymentType === "UserCredit") {
      // Check if user has sufficient credit
      const record = await pb.collection("users").getOne(bookingDetails?.owner);
      if (record && record?.tempBalance > bookingDetails?.due) {
        // Subtract the used credit from the user's balance
        await pb.collection("users").update(bookingDetails?.owner, {
          tempBalance: record?.tempBalance - bookingDetails?.due,
        });
      } else {
        lowBalanceError.value = true;
        // Throw error if user credit is insufficient
        throw new Error("Insufficient credit for this booking");
      }
    }

    // Create a docket payment record
    const docketPaymentResponse = await pb
      .collection("docketPayments")
      .create(docketPaymentData);

    if (docketPaymentResponse) {
      // Update the docket due, paid, and status keys
      await pb.collection("dockets").update(docketId?.value, {
        user: bookingDetails?.owner,
        source: "Booking",
        due: 0,
        status: "Paid",
      });

      // Update the booking due, paid, and status keys
      await pb.collection("bookings").update(bookingId, {
        due: 0,
        paid: bookingDetails?.paid + bookingDetails?.due,
        state: "Confirmed",
        payMethod: Array.from(
          new Set([
            ...(bookingDetails?.payMethods || []),
            paymentType === "UserCredit" ? "Account Credit" : "Fee Waived",
          ])
        ),
      });
    }

    // return;
  };

  /**
   * Creates or updates a docket and docket line for a customized purchase.
   *
   * @param {Object} payment - The payment object containing details such as location, message, amount, loggedInUserId, and other relevant information.
   * @param {string} type - The type of purchase, which can be either 'transferCredit', 'giftCard', 'coupon', or 'addCredit'.
   *
   * @returns {Promise<void>} - A promise that resolves when the docket and docket line have been created or updated.
   */
  const createEditDocketForCustomizePurchase = async (
    payment: any,
    type: string
  ) => {
    try {
      const transactionType = {
        transferCredit: "UserCredit",
        giftCard: "Gift Card",
        coupon: "Coupon",
        addCredit: "UserCredit",
      }[type];

      // Check if docketId exists, if not, create or update docket
      if (!docketId.value) {
        const docketData = await pb.collection("dockets").create({
          location: payment?.location,
          description: payment?.message,
          status: "Open",
          due: payment?.amount,
          total: payment?.amount,
          createdBy: payment?.loggedInUserId,
          user: payment?.owner,
          source: "Booking",
        });
        docketId.value = docketData.id;
      } else {
        await pb.collection("dockets").update(docketId.value, {
          user: payment?.owner,
          source: "Booking",
          description: payment?.message,
          due: payment?.amount,
        });
      }

      // Check if docketLineId exists, if not, create or update docket line
      if (!docketLineId.value) {
        const docketLineData = await pb.collection("docketLines").create({
          docket: docketId.value,
          transactionType,
          qty: 1,
          itemPrice: payment?.amount,
          lineTotal: payment?.amount,
          status: "Available",
          createdBy: payment?.loggedInUserId,
          ...(["addCredit", "transferCredit"].includes(type) && {
            userAccountCredit: payment?.toUserId,
          }),
        });
        docketLineId.value = docketLineData.id;
      } else {
        await pb.collection("docketLines").update(docketLineId.value, {
          qty: 1,
          itemPrice: payment?.amount,
          lineTotal: payment?.amount,
        });
      }
    } catch (e) {}
  };

  /**
   * Creates a new docket payment in the database.
   *
   * @param {Object} payment - The payment object containing details such as amount, loggedInUserId, and other relevant information.
   * @param {string} paymentType - The type of payment, which can be either 'User Account' or 'Gift Card'.
   *
   * @returns {Promise<any>} - A promise that resolves with the newly created docket payment object.
   */
  const createDocketPayment = async (payment: any, paymentType: string) => {
    const paymentData: any = {
      docket: docketId.value,
      paymentType,
      status: "Approved",
      amount: Number(payment.amount),
      createdBy: payment?.loggedInUserId,
    };

    // Conditionally add fields based on paymentType
    if (paymentType === "User Account") {
      paymentData.user = payment?.owner;
    } else if (paymentType === "Gift Card") {
      paymentData.giftCard = payment?.identifier;
    }

    return await pb.collection("docketPayments").create(paymentData);
  };

  const updateDocketStatus = async (loggedInUserId: string) => {
    await pb.collection("dockets").update(docketId.value, {
      due: 0,
      updatedBy: loggedInUserId,
      status: "Paid",
    });
  };

  /**
   * Creates a new coupon in the database.
   *
   * @param {Object} payment - The payment object containing details such as coupon quantity, expiry interval, and user details.
   * @param {number} payment.couponQty - The quantity of coupons to be created.
   * @param {string} payment.expiryInterval - The interval at which the coupons will expire (e.g., "Days", "Weeks", "Months").
   * @param {string} payment.owner - The ID of the user who will own the coupons.
   *
   * @returns {Promise<string>} - A promise that resolves with the ID of the newly created coupon.
   */
  const createCoupon = async (payment: any) => {
    let expiryTime = "";
    if (payment?.expiryInterval === "Days") {
      expiryTime = moment().add(1, "days").format("YYYY-MM-DD HH:mm:ss");
    } else if (payment?.expiryInterval === "Weeks") {
      expiryTime = moment().add(1, "weeks").format("YYYY-MM-DD HH:mm:ss");
    } else if (payment?.expiryInterval === "Months") {
      expiryTime = moment().add(1, "months").format("YYYY-MM-DD HH:mm:ss");
    }

    const couponData = await pb.collection("coupons").create({
      name: "test",
      qty: payment?.couponQty,
      activity: "",
      qtyRemaining: payment?.couponQty,
      expiryTime: expiryTime,
      expired: false,
      user: payment?.owner,
    });

    return couponData?.id;
  };

  const updateDocketLine = async (loggedInUserId: string, updateData: any) => {
    await pb.collection("docketLines").update(docketLineId.value, {
      updatedBy: loggedInUserId,
      ...updateData,
    });
  };

  /**
   * Processes payments made by user credit.
   *
   * @param {Object} payment - The payment object containing details such as amount, owner, loggedInUserId, and other relevant information.
   * @param {string} type - The type of payment, which can be either 'giftCard' or 'coupon'.
   *
   * @throws {Error} - If an error occurs during the payment processing, it will be thrown.
   */
  const payByUserCredit = async (payment: any, type: string) => {
    try {
      const record = await pb.collection("users").getOne(payment?.owner);
      if (record && record?.tempBalance > payment?.amount) {
        let createdId;

        if (type === "giftCard") {
          const giftCardData = await pb.collection("giftCards").create({
            originalAmount: payment?.amount,
            balanceRemaining: payment?.amount,
            createdBy: payment?.loggedInUserId,
            to: payment?.to,
            from: payment?.from,
            message: payment?.message,
            paid: false,
          });
          createdId = giftCardData?.id;
        } else if (type === "coupon") {
          createdId = await createCoupon(payment);
        }

        record.tempBalance -= payment?.amount;
        await pb.collection("users").update(payment?.owner, record);

        const docketPayment = await createDocketPayment(
          payment,
          "User Account"
        );

        if (docketPayment) {
          await updateDocketStatus(payment?.loggedInUserId);

          const docketLineUpdateData = {
            ...(type === "giftCard" && { giftCard: createdId }),
            ...(type === "coupon" && {
              coupon: createdId,
              couponQty: payment?.couponQty,
            }),
            ...(type === "addCredit" && {
              userAccountCredit: payment?.toUserId,
            }),
            updatedBy: payment?.loggedInUserId,
          };
          await updateDocketLine(payment?.loggedInUserId, docketLineUpdateData);

          if (type === "giftCard") {
            await pb.collection("giftCards").update(createdId, {
              paid: true,
              requiresEmail: true,
              email: payment.emailAddress,
            });
          }
        }
      } else {
        lowBalanceError.value = true;
        throw new Error("Your available balance is not sufficient");
      }
    } catch (e) {
      throw e;
    }
  };

  /**
   * Processes payments made through gift cards.
   *
   * @param {Object} payment - The payment object containing details such as amount, identifier, loggedInUserId, and other relevant information.
   * @param {string} type - The type of payment, which can be either 'giftCard' or 'coupon'.
   *
   * @throws {Error} - If an error occurs during the payment processing, it will be thrown.
   */
  const payByGiftCard = async (payment: any, type: string) => {
    try {
      // TODO: REMOVE CODE AFTER SERVER UPDATED WITH REAL VALUE OF COUPONS/GIFT
      // const giftCardDetails = await pb
      //   .collection("giftCards")
      //   .getOne(payment?.identifier);
      // giftCardDetails.balanceRemaining -= payment.amount;
      // await pb
      //   .collection("giftCards")
      //   .update(payment?.identifier, giftCardDetails);

      const docketPayment = await createDocketPayment(payment, "Gift Card");

      if (docketPayment) {
        await updateDocketStatus(payment?.loggedInUserId);

        if (type === "coupon") {
          const createdId = await createCoupon(payment);
          const docketLineUpdateData = {
            coupon: createdId,
            couponQty: payment?.couponQty,
          };
          await updateDocketLine(payment?.loggedInUserId, docketLineUpdateData);
        } else {
          await updateDocketLine(payment?.loggedInUserId, {
            userAccountCredit: payment?.toUserId,
          });
        }
      }
    } catch (e) {}
  };

  /**
   * Processes payments made through Point of Sale (POS) for gift cards or coupons.
   *
   * @param {Object} payment - The payment object containing details such as amount, type, and user details.
   * @param {string} type - The type of payment, which can be either 'giftCard' or 'coupon'.
   *
   * @throws {Error} - If an error occurs during the payment processing, it will be thrown.
   */
  const payByPos = async (payment: any, type: string) => {
    try {
      const docketLineUpdateData = {
        ...(type === "coupon" && {
          couponQty: payment?.couponQty,
        }),
        ...(["transferCredit", "addCredit"].includes(type) && {
          userAccountCredit: payment?.toUserId,
        }),
        updatedBy: payment?.loggedInUserId,
      };
      await updateDocketLine(payment?.loggedInUserId, docketLineUpdateData);
    } catch (e) {
      throw e;
    }
  };

  /**
   * Processes a booking by creating or updating a docket line.
   *
   * @param booking - The booking object containing details such as activity, duration, and value.
   * @param docketId - The ID of the existing docket.
   * @param loggedInUser - The ID of the logged-in user.
   * @param activityMap - A map containing activity details.
   *
   * @returns {Promise<{ newDocketLine: any }>} - A promise that resolves with an object containing the new docket line.
   *
   * @throws {Error} - If an error occurs during the process, it will be thrown.
   */
  const processBooking = async (
    booking: any,
    docketId: string,
    loggedInUser: string,
    activityMap: any
  ) => {
    try {
      const discountRate = booking?.discount || 0; // percentage discount
      const sessionPrice = activityMap[booking?.activity]?.sessionFullPrice;
      const qty =
        booking?.duration / activityMap[booking?.activity]?.bookingSpacing;
      const itemPrice = qty * sessionPrice;

      // Calculate the total discount amount and due amount after payment
      const discountAmount = (itemPrice * discountRate) / 100;
      const due = itemPrice - discountAmount - (booking?.paid || 0);

      let newDocketLine = booking?.docketLine;

      // If no docketLine exists, create a new one
      if (!newDocketLine) {
        // Step 2: Create a new docket line under the existing docket
        newDocketLine = await pb.collection("docketLines").create({
          docket: docketId,
          transactionType: "Event",
          qty,
          itemPrice,
          lineTotal: itemPrice - discountAmount,
          discount: discountRate,
          status: "Available",
          booking: booking?.id,
          createdBy: loggedInUser,
        });

        // Step 3: Update the booking with the new docketLine
        await pb.collection("bookings").update(booking.id, {
          due: due,
          discount: discountRate,
          docketLine: newDocketLine.id,
          updatedBy: loggedInUser,
        });
      }
      return { newDocketLine };
    } catch (error) {
      console.error("Error creating or updating docket line:", error);
      throw error;
    }
  };

  /**
   * Processes event payments by Point of Sale (POS).
   *
   * @param params - An object containing the following properties:
   *   - eventId: The ID of the event.
   *   - loggedInUser: The ID of the logged-in user.
   *   - activityMap: A map containing activity details.
   *   - due: The total amount due for the event.
   *
   * @returns {Promise<void>} - A promise that resolves when the event payment processing is complete.
   */
  const eventDuePayByPos = async (params: any) => {
    try {
      const { eventId, loggedInUser, activityMap, due } = params;
      const eventData = await pb.collection("events").getOne(eventId, {
        expand: "bookings",
      });

      // Check if any bookings have a docket; if not, create a new one
      for (const booking of eventData?.expand?.bookings || []) {
        if (!booking?.docketLine && booking?.due !== 0) {
          console.log(
            `Creating new docket or adding lines for booking ${booking.id}`
          );

          // If no docket has been created yet, create one
          if (!docketId.value) {
            const newDocket = await pb.collection("dockets").create({
              location: params?.location,
              status: "Open",
              due: due,
              total: due,
              user: params?.owner,
              source: "Booking",
              createdBy: loggedInUser,
            });
            docketId.value = newDocket.id;
          }

          // Process the booking and add docketLines under the same docket
          await processBooking(
            booking,
            docketId.value,
            loggedInUser,
            activityMap
          );
        }
      }
    } catch (e) {
      console.error("Error processing event payment by POS:", e);
    }
  };

  /**
   * Processes event payments by waiving the fee.
   *
   * @param params - An object containing the following properties:
   *   - eventId: The ID of the event.
   *   - loggedInUser: The ID of the logged-in user.
   *   - activityMap: A map containing activity details.
   *   - due: The total amount due for the event.
   *
   * @returns {Promise<void>} - A promise that resolves when the event payment processing is complete.
   */
  const eventDuePayByWaiveFee = async (params: any) => {
    try {
      const { eventId, loggedInUser, activityMap, due } = params;
      const eventData = await pb.collection("events").getOne(eventId, {
        expand: "bookings",
      });

      let docketNeedsUpdate = false; // Flag to check if docket needs to be updated
      let bookingDues = 0;

      // Iterate over all bookings in the event
      for (const booking of eventData?.expand?.bookings || []) {
        if (booking?.due !== 0) {
          bookingDues += booking?.due;
          console.log(`Processing booking ${booking.id} for fee waiver`);

          // Create a docket if one doesn't exist for this event
          if (!docketId.value) {
            const newDocket = await pb.collection("dockets").create({
              location: params?.location,
              status: "Open",
              due: due,
              total: due,
              user: params?.owner,
              source: "Booking",
              createdBy: loggedInUser,
            });
            docketId.value = newDocket.id;
          }

          // Process the booking and add docketLines under the same docket
          await processBooking(
            booking,
            docketId.value,
            loggedInUser,
            activityMap
          );

          // Mark that the docket will need updating after all bookings are processed
          docketNeedsUpdate = true;

          // Update the booking with payment details (but not the docket yet)
          await pb.collection("bookings").update(booking.id, {
            due: 0,
            paid: booking?.due,
            paymentType: "Fee Waived",
            updatedBy: loggedInUser,
          });
        }
      }

      // Step 4: create docketPayment record
      if (bookingDues) {
        await pb.collection("docketPayments").create({
          docket: docketId.value,
          paymentType: "Fee Waived",
          status: "Approved",
          amount: bookingDues,
          createdBy: loggedInUser,
        });
      }

      // Step 5: After all bookings are processed, update the docket if needed
      if (docketNeedsUpdate && docketId.value) {
        await pb.collection("dockets").update(docketId.value, {
          user: params?.owner,
          source: "Booking",
          due: 0,
          updatedBy: loggedInUser,
          status: "Paid",
        });
      }
    } catch (e) {
      console.error("Error processing event payment by fee waiver:", e);
    }
  };

  const initiateDocketForEvent = async (eventDetails: any) => {
    const { eventId, loggedInUser, activityMap, due } = eventDetails;
    const eventData = await pb.collection("events").getOne(eventId, {
      expand: "bookings",
    });

    let bookingValue = 0;

    // Iterate over all bookings in the event
    for (const booking of eventData?.expand?.bookings || []) {
      if (booking?.due !== 0) {
        bookingValue += booking.value;

        // Create a docket if one doesn't exist for this event
        if (!docketId.value) {
          const discountSources = eventDetails?.discountEvents
            ? ["DiscountCode"]
            : [];

          const newDocket = await pb.collection("dockets").create({
            location: eventDetails?.location,
            discountProduct: eventDetails?.discountProduct,
            discountBookings: eventDetails?.discountBookings,
            discountEvents: eventDetails?.discountEvents,
            due: due,
            total: due,
            status: "Open",
            user: eventDetails?.owner,
            source: "Booking",
            discountSources,
            createdBy: loggedInUser,
          });
          docketId.value = newDocket.id;
        }

        // Process the booking and add docketLines under the same docket
        await processBooking(
          { ...booking },
          docketId.value,
          loggedInUser,
          activityMap
        );
      }
    }
  };

  return {
    couponList,
    docketId,
    docketLineId,
    docketPaymentId,
    lowBalanceError,
    isPendingBooking,
    getCouponList,
    payWithGiftCardOrCoupon,
    initiateDocketForBooking,
    handleUserCreditAndWaiveFeePayment,
    payWithAutoApplyCoupons,
    createEditDocketForCustomizePurchase,
    payByUserCredit,
    payByGiftCard,
    payByPos,
    eventDuePayByPos,
    eventDuePayByWaiveFee,
    initiateDocketForEvent,
  };
});
