import { pb } from "@/utils/PocketBaseAdapter";
import { moment } from "@/utils/useTimeZone";
import { getBookingStatus } from "@/utils/Formatting";
import { useListStore } from "./listStore";
import { useUserStore } from "@/utils/UserStore";
import type { Area, Booking, TimeSlot } from "@/store/types";

export const useBooking = defineStore("booking", () => {
  const list = useListStore();
  const userStore = useUserStore();

  const { getListArea } = list;

  const { listArea: areasList } = storeToRefs(list);
  const { currentLocation } = storeToRefs(userStore);

  // data
  const areaLists = ref<any[]>([]);
  const listArea = ref<any[]>([]);
  const editBookingData = ref<any>({});
  const timeRanges = ref<any>([]);
  const activityList = ref<any[]>([]);
  const selectedActivity = ref<any>({});
  const membershipStartHour = ref<number | any>();
  const membershipFinishHour = ref<number | any>();
  const recurringBookingId = ref<any>("");

  // Methods
  const generateTimeSlots = (
    startHour: number,
    finishHour: number,
    spacing: number,
    selectedDate: any,
    bookings: Array<Booking>,
    recurringBookingsList: any[],
    eventsList: any[],
    recurringEventsList: any[]
  ): Array<TimeSlot> => {
    const timeSlots: Array<TimeSlot> = [];
    const now = moment();
    const isToday = selectedDate.isSame(now, "day");
    const isYesterday = selectedDate.isSame(
      now.clone().subtract(1, "day"),
      "day"
    );

    const getBooking = (timeForComparison: any) => {
      return bookings?.find((booking) => {
        const bookingStart = moment(booking.startTime);
        const bookingEnd = moment(booking.endTime);
        return (
          bookingStart.isSameOrBefore(timeForComparison) &&
          bookingEnd.isAfter(timeForComparison)
        );
      });
    };

    const getRecurringConfig = (booking: Booking) => {
      return recurringBookingsList.find((recurring) =>
        recurring.bookings.includes(booking.id)
      );
    };

    const getEventConfig = (booking: Booking) => {
      return eventsList.find((event) => event.bookings.includes(booking.id));
    };

    const getRecurringEvent = (booking: any) => {
      if (!booking) {
        return;
      }
      return recurringEventsList?.find((list: any) => {
        return list?.expand?.events?.find((event: any) => {
          return event?.expand?.bookings?.find(
            (book: any) => book.id === booking.id
          );
        });
      });
    };

    // Generate two past slots if today
    if (isToday) {
      let currentTime = moment(selectedDate).set({
        hour: moment().hour(),
        minute: moment().minute(),
        second: 0,
      });

      const firstPastSlot = now
        .clone()
        .startOf("minute")
        .add(spacing - (now.minute() % spacing), "minutes")
        .subtract(spacing, "minutes"); // Move to one slot before the next slot
      const secondPastSlot = firstPastSlot.clone().subtract(spacing, "minutes");
      [secondPastSlot, firstPastSlot]?.forEach((slot) => {
        const formattedStartTime = slot.format("HH:mm:ss");
        const timeComparison = moment(selectedDate).set({
          hour: slot.hour(),
          minute: slot.minute(),
          second: 0,
        });

        if (!timeSlots?.find((slot) => slot.startTime === formattedStartTime)) {
          const booking = getBooking(timeComparison);
          const recurringBookingConfig = booking
            ? getRecurringConfig(booking)
            : null;
          const eventConfig = booking ? getEventConfig(booking) : null;
          const recurringEvent = getRecurringEvent(booking);

          timeSlots.push({
            startTime: formattedStartTime,
            label: slot.format("h:mm a"),
            isNormalHour: false,
            isPastHour: currentTime.isBefore(now),
            ...(booking && {
              isBooked: true,
              bookingDetails: { ...booking, recurring: recurringBookingConfig },
              ...(recurringBookingConfig && { isRecurring: true }),
              ...(eventConfig && {
                isEvent: true,
                event: eventConfig,
                ...(recurringEvent && {
                  isRecurringEvent: true,
                  recurringEventDetails: {
                    id: recurringEvent?.id,
                    days: recurringEvent?.days,
                    endDate: recurringEvent?.endDate,
                    events: recurringEvent?.events,
                    expand: recurringEvent?.expand,
                    frequency: recurringEvent?.frequency,
                    interval: recurringEvent?.interval,
                  },
                }),
              }),
            }),
          });
        }
      });
    }

    // Generate slots for the entire day
    for (let hour = 0; hour < 24; hour++) {
      let currentTime = moment(selectedDate).set({
        hour,
        minute: 0,
        second: 0,
      });

      // Skip past times if the selected date is today
      if (isToday && currentTime.isBefore(now)) {
        currentTime = now
          .clone()
          .startOf("minute")
          .add(spacing - (now.minute() % spacing), "minutes");
        if (currentTime.hour() !== hour) {
          continue;
        }
      }

      while (currentTime.hour() === hour) {
        const formattedStartTime = currentTime.format("HH:mm:ss");

        // Do not generate slots for the next day
        if (currentTime.isAfter(moment(selectedDate).endOf("day"))) {
          break;
        }

        // Create a time for comparison using the selected date and current time
        const timeForComparison = moment(selectedDate).set({
          hour: currentTime.hour(),
          minute: currentTime.minute(),
          second: 0,
        });

        if (!timeSlots?.find((slot) => slot.startTime === formattedStartTime)) {
          const booking = getBooking(timeForComparison);
          const recurringBookingConfig = booking
            ? getRecurringConfig(booking)
            : null;
          const eventConfig = booking ? getEventConfig(booking) : null;
          const recurringEvent = getRecurringEvent(booking);

          if (booking) {
            booking.status = getBookingStatus(
              booking.actualStartTime,
              booking.actualDuration
            );
          }

          const isNormalHour =
            (hour >= startHour && hour < finishHour) ||
            (finishHour === 0 && hour >= startHour);
          const isPastHour = currentTime.isBefore(now);

          timeSlots.push({
            startTime: formattedStartTime,
            label: currentTime.format("h:mm a"),
            isNormalHour,
            isPastHour,
            ...(booking && {
              isBooked: true,
              bookingDetails: { ...booking, recurring: recurringBookingConfig },
              ...(recurringBookingConfig && { isRecurring: true }),
              ...(eventConfig && {
                isEvent: true,
                event: eventConfig,
                ...(recurringEvent && {
                  isRecurringEvent: true,
                  recurringEventDetails: {
                    id: recurringEvent?.id,
                    days: recurringEvent?.days,
                    endDate: recurringEvent?.endDate,
                    events: recurringEvent?.events,
                    expand: recurringEvent?.expand,
                    frequency: recurringEvent?.frequency,
                    interval: recurringEvent?.interval,
                  },
                }),
              }),
            }),
          });
        }
        currentTime = currentTime.add(spacing, "minutes");
      }
    }

    // For yesterday, trim to only the last two slots
    if (isYesterday) {
      const slotList = timeSlots
        .slice(-1) // Get the last two slots
        .sort((a, b) =>
          moment(a.startTime, "HH:mm:ss").diff(moment(b.startTime, "HH:mm:ss"))
        );

      return slotList;
    }

    return timeSlots.sort((a, b) =>
      moment(a.startTime, "HH:mm:ss").diff(moment(b.startTime, "HH:mm:ss"))
    );
  };

  /**
   * Retrieves and processes area lists based on the provided activity, location, date, and membership.
   * It fetches areas, bookings, and time ranges, then generates time slots for each area.
   * The function also merges time slots for each area, grouping them by bookingDetails.id.
   *
   * @param activity - The activity object for which to retrieve areas.
   * @param location - The location of the areas.
   * @param date - The date for which to retrieve areas and bookings.
   * @param membership - The membership for which to adjust time slot availability.
   * @returns An array of areas with their respective time slots.
   */
  const getAreaLists = async (activity: any, date: any, membership: string) => {
    try {
      const dayOfWeek = moment(date, "YYYY-MM-DD")
        .format("dddd")
        ?.toLowerCase();

      // Convert local time boundaries to UTC time
      const startOfDayUTC = moment(date)
        .startOf("day")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      const endOfDayUTC = moment(date)
        .endOf("day")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      const areas = listArea?.value?.filter((area: Area) =>
        area?.activities?.includes(activity?.id)
      );

      const getBookingList: Booking[] = await pb
        .collection("bookings")
        .getFullList({
          filter: `startTime <= '${endOfDayUTC}' && endTime >= '${startOfDayUTC}' && state != 'Deleted'`,
          expand: "users,docketLine",
        });

      // Extract booking IDs from the getBookingList
      const bookingIds = getBookingList.map((booking) => booking.id);

      const [recurringBookingsList, eventsList] = bookingIds.length
        ? await Promise.all([
            pb.collection("recurringBookings").getFullList({
              filter: bookingIds.map((id) => `bookings ~ '${id}'`).join(" || "),
            }),
            pb.collection("events").getFullList({
              filter: bookingIds.map((id) => `bookings ~ '${id}'`).join(" || "),
              expand: "bookings.owner,bookings.docketLine.docket,bookings.area", // Ensure to expand all necessary fields
            }),
          ])
        : [[], []];

      const recurringEventsList = eventsList.length
        ? await pb.collection("recurringEvents").getFullList({
            filter: eventsList
              .map((event) => `events ~ '${event.id}'`)
              .join(" || "),
            expand: "events.bookings.docketLine.docket",
          })
        : [];

      const timeRange = timeRanges?.value?.filter(
        (el: any) => el[dayOfWeek] === true
      );

      const [startHour, finishHour] = timeRange?.length
        ? [
            Math.min(...timeRange?.map((el: any) => el.startHour)),
            Math.max(...timeRange?.map((el: any) => el.finishHour)),
          ]
        : (["", ""] as any[]);

      if (membership) {
        // Find the time range that includes the user's membership
        let priorityBookingTimeList = timeRanges.value?.find(
          (item: any) =>
            item?.memberships?.includes(membership) && item[dayOfWeek] === true
        );
        if (priorityBookingTimeList) {
          // const formatHour = (hour: number): string => {
          //   const hour24 = hour % 24; // Ensure the hour is within 24-hour range
          //   return `${hour24.toString().padStart(2, "0")}:00:00`;
          // };

          // membershipStartHour.value = formatHour(
          //   priorityBookingTimeList?.startHour || 0
          // );
          // membershipFinishHour.value = formatHour(
          //   priorityBookingTimeList?.finishHour || 23
          // );
          membershipStartHour.value = "00:00:00";
          membershipFinishHour.value = "23:00:00";
        }
      }

      // assign bookingStatus
      getBookingList?.forEach((booking: any) => {
        booking.status = getBookingStatus(
          booking.actualStartTime,
          booking.actualDuration
        );
      });

      const updatedAreas = areas?.map((area) => {
        const areaBookings = getBookingList?.filter(
          (booking: any) => booking.area === area.id
        );
        const timeSlots = generateTimeSlots(
          startHour,
          finishHour,
          activity?.bookingSpacing,
          moment(date),
          areaBookings,
          recurringBookingsList,
          eventsList,
          recurringEventsList
        );

        return { ...area, timeSlots };
      });

      /**
       * Merges time slots for each area, grouping them by bookingDetails.id.
       * Handles time slots without bookingDetails or bookingDetails.id separately.
       *
       * @param updatedAreas - An array of areas with their respective time slots.
       * @returns An array of areas with merged time slots.
       */
      const mergeTimeSlots = updatedAreas?.map((area) => {
        const timeSlotsMap = area?.timeSlots?.reduce(
          (map: any, slot: TimeSlot) => {
            const bookingId = slot?.bookingDetails?.id || slot.label;
            if (!map[bookingId]) {
              map[bookingId] = {
                ...slot,
                slotCount: slot?.bookingDetails?.id ? 1 : undefined,
              };
            } else if (map[bookingId]?.slotCount) {
              map[bookingId].slotCount++;
            }
            return map;
          },
          {}
        );

        return { ...area, timeSlots: Object.values(timeSlotsMap) };
      });

      areaLists.value = mergeTimeSlots;
    } catch (e) {
      console.error(e);
    }
  };

  const getActivityList = async (
    membership: string = "",
    date: string = ""
  ) => {
    try {
      const records = await pb.collection("activities").getFullList({
        expand: "availibleTimes,TODPricing",
        filter: `location = '${currentLocation.value}'`,
      });

      if (records && records.length) {
        activityList.value = records;
        selectedActivity.value = activityList.value[0];
        timeRanges.value = selectedActivity.value?.expand?.availibleTimes;
        // fetch area with timeSlots
        await getAreaLists(
          selectedActivity.value,
          date || moment().format("YYYY-MM-DD"),
          membership
        );
      }
    } catch (e) {
      console.error("Error fetching activity list:", e);
    }
  };

  const getAllAreas = async () => {
    try {
      await getListArea();
      listArea.value = areasList.value;
    } catch (e) {}
  };

  const clearBookingStore = () => {
    areaLists.value = [];
    editBookingData.value = {};
    timeRanges.value = [];
    activityList.value = [];
    selectedActivity.value = {};
    membershipFinishHour.value = undefined;
    membershipStartHour.value = undefined;
  };

  return {
    editBookingData,
    areaLists,
    activityList,
    selectedActivity,
    membershipStartHour,
    membershipFinishHour,
    timeRanges,
    listArea,
    recurringBookingId,
    getAreaLists,
    getActivityList,
    clearBookingStore,
    getBookingStatus,
    getAllAreas,
  };
});
