import type { userInfo } from "@/@layouts/types";
import type {
  LocationRecordModel,
  UserRecordModel,
} from "@/utils/PocketBaseAdapter";
import { pb } from "@/utils/PocketBaseAdapter";
import moment from "moment-timezone";
import { defineStore } from "pinia";
import type { RecordOptions } from "pocketbase";

export const useUserStore = defineStore("UserStore", {
  state: () => ({
    userId: pb.authStore.model?.id,
    usersCurrentLocation: (pb.authStore.model as UserRecordModel)?.homeLocation,
    currentLocation:
      localStorage.getItem("homeLocation") ||
      (pb.authStore.model as UserRecordModel)?.homeLocation,
    currentLocationDetails: <LocationRecordModel>{},
    userInfo: {} as userInfo,
    usersList: ref<any[]>([]),
    currentTime: ref(moment().tz("Pacific/Auckland")),
  }),
  actions: {
    // 👉 Fetch users data
    async fetchUsers(params?: any) {
      params.location ??= this.usersCurrentLocation;

      let filters = "";

      if (params?.role) {
        filters += filters
          ? ` && roles ~ "${params.role}"`
          : `roles ~ "${params.role}"`;
      }

      if (params?.status) {
        filters += filters
          ? ` && status = "${params.status}"`
          : `status = "${params.status}"`;
      }

      if (params?.membership) {
        filters += filters
          ? ` && membership="${params.membership}"`
          : `membership="${params.membership}"`;
      }

      if (params?.location) {
        filters += filters
          ? ` && homeLocation="${params.location}"`
          : `homeLocation="${params.location}"`;
      }

      if (params?.q) {
        const query = `firstName ~ "${params.q}" || lastName ~ "${params.q}" || email ~ "${params.q}"`;

        filters += filters ? ` && ${query}` : query;
      }

      let sortParam = "";
      if (params?.sortBy && params?.sortBy.length) {
        const sortKey = params?.sortBy[0].key;
        const sortOrder = params?.sortBy[0].order === "desc" ? "-" : "";

        if (sortKey === "lastName") {
          sortParam = sortOrder + "lastName," + sortOrder + "firstName";
        } else {
          sortParam = sortOrder + sortKey;
        }
      }

      return await pb
        .collection("users")
        .getList<UserRecordModel>(params.page, params.itemsPerPage, {
          filter: filters,
          sort: sortParam || "",
          expand: "homeLocation",
        });
    },

    // 👉 fetch use location details
    async getCurrentLocation() {
      const record = await pb
        .collection("locations")
        .getOne(`${this.currentLocation}`, {
          requestKey: null,
        });

      this.currentLocationDetails = record;
    },

    // 👉 Add User
    // addUser(userData: UserProperties) {
    //  return new Promise((resolve, reject) => {
    //    axios.post('/apps/users/user', {
    //      user: userData,
    //    }).then(response => resolve(response))
    //      .catch(error => reject(error))
    //  })
    // },

    // 👉 fetch single user
    async fetchUser(id: string, options?: RecordOptions) {
      const record = await pb
        .collection("users")
        .getOne<UserRecordModel>(id, options);
      this.userInfo = record;
      return record;
    },

    async fetchLoggedInUser(options?: RecordOptions) {
      try {
        const userId = pb.authStore.model?.id;

        return await this.fetchUser(userId, options);
      } catch (e) {}
    },

    // 👉 Delete User
    // deleteUser(id: number) {
    //  return new Promise((resolve, reject) => {
    //    axios.delete(`/apps/users/${id}`).then(response => resolve(response)).catch(error => reject(error))
    //  })
    // },

    async getUserList() {
      try {
        if (this.usersList.length) {
          return;
        }
        const records = await pb.collection("users").getFullList({
          fields: "id,name,email,firstName,lastName,avatar,username,leftHanded",
          filter: 'status != "Suspended"',
        });
        if (records) {
          this.usersList = records;
        }
      } catch (e) {}
    },
  },
});
