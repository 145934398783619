import type {
  LocationRecordModel,
  ProductCategoryRecordModel,
  ProductRecordModel,
  ProductSubCategoryRecordModel,
  SupplierRecordModel,
  UserRecordModel,
} from "@/utils/PocketBaseAdapter";
import { collections, pb } from "@/utils/PocketBaseAdapter";
import { defineStore } from "pinia";

export const useProductStore = defineStore("ProductStore", {
  state: () => ({
    categories: new Map<string, ProductCategoryRecordModel>(),
    subCategories: new Map<string, ProductSubCategoryRecordModel>(),
    products: [] as ProductRecordModel[],
    categoryList: [] as ProductCategoryRecordModel[],
    subCategoryList: [] as ProductSubCategoryRecordModel[],
    allSubCategoryList: [] as ProductSubCategoryRecordModel[],
    locationList: [] as LocationRecordModel[],
    suppliersList: [] as SupplierRecordModel[],
    collectionId: "" as string,
  }),
  getters: {
    productMapData(state) {
      return state.products.reduce((map, product) => {
        map[product.id] = product;
        return map;
      }, {} as Record<number, (typeof state.products)[0]>);
    },
  },
  actions: {
    // 👉 fetch suppliers
    async fetchSuppliers() {
      const supplierPromise = pb
        .collection(collections.suppliers)
        .getFullList<SupplierRecordModel>();

      this.suppliersList = await supplierPromise;
    },

    async refreshStore(location?: string) {
      // this feels so wrong, but i'll leave this here - something to think about?
      // await pb.collection(collections.productCategories).getFullList<ProductCategoryRecordModel>(
      //    {
      //        expand: 'productSubCategories_via_category.products_via_subCategory'
      //    }
      // )
      location ??= (pb.authStore.model as UserRecordModel).homeLocation;

      this.categories.clear();
      this.subCategories.clear();

      const categoriesPromise = pb
        .collection(collections.productCategories)
        .getFullList<ProductCategoryRecordModel>();

      const subCategoriesPromise = pb
        .collection(collections.productSubCategories)
        .getFullList<ProductSubCategoryRecordModel>();

      (await categoriesPromise).forEach((cat) =>
        this.categories.set(cat.id, cat)
      );
      (await subCategoriesPromise).forEach((subcat) =>
        this.subCategories.set(subcat.id, subcat)
      );

      const productsPromise = pb
        .collection(collections.products)
        .getFullList<ProductRecordModel>({
          filter: `location = "${location}" && inactive = false`,
          expand: "location,category,subCategory",
        });

      this.products = await productsPromise;
    },
    async fetchProductCategories() {
      try {
        const categoriesPromise = await pb
          .collection(collections.productCategories)
          .getFullList<ProductCategoryRecordModel>({
            sort: "sortOrder",
          });

        this.categoryList = categoriesPromise;
      } catch (error) {
        console.log("Error getting product categories: ", error);
      }
    },
    async fetchProducts(location?: string, params?: any) {
      location ??= (pb.authStore.model as UserRecordModel).homeLocation;

      let filters = `location = "${location}"`;

      if (params?.category) {
        if (filters) filters += `&& category="${params?.category}"`;
        else filters = `category="${params?.category}"`;
      }
      if (params?.subCategory) {
        if (filters) filters += `&& subCategory="${params?.subCategory}"`;
        else filters = `subCategory="${params?.subCategory}"`;
      }
      if (params?.q) {
        if (filters)
          filters += `&& (description ~ "${params.q}" || name ~ "${params.q}")`;
        else filters = `description ~ "${params.q}" || name ~ "${params.q}"`;
      }

      if (params?.barcode) {
        if (filters) filters += `&& barcode ~ "${params.barcode}"`;
      }

      let sortParam = "";
      if (params?.sortBy.length) {
        sortParam =
          params?.sortBy[0].order === "desc"
            ? `-${params?.sortBy[0].key}`
            : params?.sortBy[0].key;
      }

      const productsPromise = await pb
        .collection(collections.products)
        .getList<ProductRecordModel>(params.page, params.itemsPerPage, {
          filter: filters,
          sort: sortParam || "",
          expand: "location,category,subCategory",
        });

      this.collectionId = productsPromise?.items?.[0]?.collectionId;

      return productsPromise;
    },
    async getSubcategories(category: string) {
      if (!category) return false;

      const subCategoriesPromise = await pb
        .collection(collections.productSubCategories)
        .getFullList<ProductSubCategoryRecordModel>({
          filter: `category = "${category}"`,
        });

      this.subCategoryList = subCategoriesPromise;
    },
    async getAllSubcategories() {
      const allSubCategoriesPromise = await pb
        .collection(collections.productSubCategories)
        .getFullList<ProductSubCategoryRecordModel>({
          sort: "sortOrder",
          expand: "category",
        });

      this.allSubCategoryList = allSubCategoriesPromise;
    },
  },
});
